import '../projects.css';

const SnowboardResortsHome = () => {
    
    const content = (
        <div className="page">
            <header><h1>Snowboards Resorts App</h1></header>
        </div>
    )
    return content
   
}

export default SnowboardResortsHome