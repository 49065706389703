import './home.css'

const Home = () => {
        const content = (
            <div className="page">
                <header className="header">Welcome to Steep Carve!</header>
                <main>
                </main>
            </div>
        )
        return content
}

export default Home